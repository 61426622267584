.modal-lay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: backdrop-filter 0.3s ease;
}

.modal-con {
  background: #ffffff;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  max-width: 500px;
  width: 90%;
  position: relative;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #ff5c5c;
  border: none;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 1.5rem;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.close-button:hover {
  transform: scale(1.1);
}

.subcategory-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.subcategory-list li {
  display: flex;
  align-items: center;
  margin: 10px 0;
  padding: 10px;
  border-radius: 10px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  cursor: pointer;
}

.subcategory-list li:hover {
  background-color: #f0f8ff;
  transform: translateX(5px);
}

.subcategory-list label {
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.subcategory-list label svg {
  margin-right: 10px;
  color: #00a8ff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .modal-con {
    max-width: 90%;
    padding: 15px;
  }

  .close-button {
    top: 5px;
    right: 5px;
    font-size: 1.25rem;
  }

  .subcategory-list label {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .modal-con {
    max-width: 95%;
    padding: 10px;
  }

  .close-button {
    font-size: 1rem;
  }

  .subcategory-list label {
    font-size: 0.85rem;
  }
}
