.fish-tank {
  position: relative;
  width: 100%;
  height: 300px;
  background: url("../../assets/images/fishesline/fishline2.jpg") no-repeat
    center center/cover; /* Water Color */
  border-radius: 10px;
  overflow: hidden;
}

/* Fish */
.fish {
  position: absolute;
  bottom: 20px;
  width: 100px;
  height: 50px;
  background-image: url("../../assets/images/fish11.svg");
  background-size: contain;
  background-repeat: no-repeat;
  animation: swim 10s linear infinite;
}

.fish1 {
  left: -50px;
  animation-delay: 0s;
}
.fish2 {
  top: 30px;
  left: -60px;
  animation-delay: 2s;
}
.fish3 {
  top: 10px;
  left: -50px;
  animation-delay: 3s;
}
.fish4 {
  top: 40px;
  left: -60px;
  animation-delay: 2s;
}
.fish5 {
  top: 15px;
  left: -60px;
  animation-delay: 4s;
}
.fish6 {
  top: 25px;
  left: -60px;
  animation-delay: 5s;
}

@keyframes swim {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(1200%);
  }
}

/* Water Bubbles */
.bubble {
  position: absolute;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  animation: rise 4s infinite ease-in-out;
}

.bubble1 {
  left: 20%;
  width: 20px;
  height: 20px;
}
.bubble2 {
  left: 40%;
  width: 15px;
  height: 15px;
  animation-duration: 5s;
}
.bubble3 {
  left: 60%;
  width: 25px;
  height: 25px;
  animation-duration: 3s;
}
.bubble4 {
  left: 80%;
  width: 10px;
  height: 10px;
  animation-duration: 6s;
}

@keyframes rise {
  0% {
    transform: translateY(100%);
    opacity: 1;
  }
  100% {
    transform: translateY(-600%);
    opacity: 0;
  }
}
