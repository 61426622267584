.home {
  position: relative;
  max-width: 95%;
  margin: 0 auto;
  margin-top: 90px;
  padding: 0;
}
.colorful-bar-home {
  background: linear-gradient(90deg, #0b2f9f 0%, #98ded9 100%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.hero {
  position: relative;
  margin-bottom: 40px;
}

.hero img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  overflow: hidden;
}

.mission-vision {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
  color: #ffff;
}

.mission,
.vision {
  min-height: 300px;
  flex-basis: 48%;
  padding: 20px;
  box-shadow: 10px 0 20px rgb(11, 44, 100, 0.2);
  border-radius: 50px 0 50px 0;
  background-color: rgb(11, 44, 100, 0.7);
}

.core-val {
  background-color: rgb(11, 44, 100, 0.7);
}
.core-val,
.core-val-img {
  flex-basis: 48%;
  padding: 20px;
  border-radius: 50px 0 50px 0;
  box-shadow: 10px 0 20px rgb(11, 44, 100, 0.2);
}

.core-val h1 {
  color: #ffff;
}
.core-val h2,
.core-val-img h2 {
  color: black;
  margin-bottom: 15px;
}
.core-val p,
.core-val-img p {
  font-weight: bold;
  margin-bottom: 10px;
}
.core-val-img {
  padding: 0;
  margin-top: 10px;
}
.animated-img {
  width: 100%;
  height: 510px;
  border-style: dotted;
  border-radius: 50px 0 50px;
  transition: transform 0.3s ease, margin 0.3s ease;
  overflow: hidden;
  object-fit: cover;
}

.middle-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  padding: 20px;
  text-align: center;
  background-color: rgba(11, 44, 100, 0.6);
  border-radius: 10px;
  max-width: 80%;
  z-index: 1;
}

.middle-div-h3 {
  margin-top: 10px;
}

.middle-div-h3 h3 {
  margin: 0;
}

.middle-div h2 {
  font-size: 36px;
}
.animated-img:hover {
  animation: rotate 6s linear infinite, pulse 2s ease-in-out infinite,
    float 3s ease-in-out infinite;
}
.mission h2,
.vision h2 {
  color: #ffff;
  margin-bottom: 10px;
}

.achievements {
  height: 600px;
  background: url("../../assets/images/homepagesliderimages/áchievements.png")
    no-repeat center center/cover;
}

.achievement-item {
  text-align: center;
}

.achievement-item h3 {
  color: #ffff;
  margin-bottom: 10px;
}

.achievement-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffff;
}

.about-preview {
  margin-bottom: 40px;
  margin-left: 30px;
}

.about-preview h2 {
  color: #1a3a6e;
  margin-bottom: 20px;
}

.colorful-divider {
  position: relative;
  height: 50px;
  background: linear-gradient(135deg, #227b94 0%, #c7ffd8 100%);
  clip-path: polygon(0 100%, 0% 0%, 100% 0%, 100% 100%);
}

.animated-divider {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
  background-color: rgb(250, 203, 234);
  margin-bottom: 40px;
  border-radius: 10px;
  position: relative;
}

.animated-divider img {
  width: 200px;
  height: 200px;
  border-radius: 0 20px 0 20px;
  transition: transform 0.3s ease, margin 0.3s ease;
}

/* Moving image away from the pointer on hover */
.animated-divider img:hover {
  transform: translateX(calc(var(--x) * -0.3)) translateY(calc(var(--y) * -0.3));
}

/* Additional animations for continuous effects */
.animated-divider img {
  animation: rotate 6s linear infinite, pulse 2s ease-in-out infinite,
    float 3s ease-in-out infinite;
}
.main-image {
  margin-top: 20px;
}

.main-image img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  overflow: hidden;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

@media (max-width: 768px) {
  .mission-vision {
    flex-direction: column;
  }
  .hero img {
    width: 100%;
    height: 350px;
  }

  .mission,
  .vision {
    margin-bottom: 20px;
  }

  .achievements {
    flex-direction: column;
  }

  .achievement-item {
    margin-bottom: 20px;
  }

  .animated-divider {
    flex-direction: row;
    align-items: center;
    overflow: hidden;
  }

  .animated-img {
    height: 300px;
    transition: none;
  }
  .animated-divider img {
    margin-bottom: 10px;
    width: 80px;
    height: 80px;
  }
  .middle-div {
    max-width: 90%;
    padding: 15px;
  }

  .middle-div h2 {
    font-size: 28px;
  }

  .middle-div-h3 h3 {
    font-size: 16px;
  }
  .core-val-img {
    margin-top: 40px;
    width: 100%;
  }
  .main-image img {
    height: 250px;
  }
}
