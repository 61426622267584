.about {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px;
}

.colorful-bar-about {
  background: linear-gradient(90deg, #0b2f9f 0%, #3327e6 100%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ffff;
}

.logo {
  max-height: 120px;
  max-width: 80%;
  border-radius: 50%;
}

.introduction,
.stats {
  margin-top: 20px;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.stats {
  background-color: rgba(11, 44, 100, 0.8);
  color: #fff;
}

.global-reach {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
}

.global-reach img {
  width: 100%;
  height: 250px;
  overflow: hidden;
  object-fit: cover;
}

.introduction h1,
.global-reach h2 {
  color: #1a3a6e;
  margin-bottom: 20px;
}

.introduction p {
  margin-bottom: 15px;
  line-height: 1.6;
}

.stats {
  display: flex;
  justify-content: space-around;
  text-align: center;
}

.stat-item {
  flex: 1;
}

.stat-item h3 {
  color: #ffff;
  margin-bottom: 10px;
}

.stat-item span {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fff;
}

@media (max-width: 768px) {
  .colorful-bar {
    height: 100px;
  }

  .logo {
    max-height: 80px;
  }

  .introduction,
  .stats {
    padding: 20px;
  }
  .global-reach {
    flex-direction: column;
    gap: 5px;
  }

  .stats {
    flex-direction: column;
  }

  .stat-item {
    margin-bottom: 20px;
  }
}
