.header {
  width: 100%;
  background-color: #ffff;

  color: black;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 1;
  top: 0;
  transition: background-color 0.3s ease;
}

.logo img {
  height: 50px;
  border-radius: 50px;
}
.logo img :hover {
  height: 0px;
  border-radius: 50px;
}

.search-container {
  flex: 1;
  margin: 0 20px;
  max-width: 400px;
}

.search-input {
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-radius: 20px;
  font-size: 1rem;
}

.nav {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  background: linear-gradient(#ffff, #ffff);
  padding: 2rem 1rem;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transform: translateX(0px);
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav ul li {
  margin-bottom: 1rem;
  position: relative;
}

.nav ul li a {
  color: black;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.3s ease,
    letter-spacing 0.3s ease;
  display: block;
  padding: 0.5rem;
  position: relative;
  text-align: center;
  overflow: hidden; /* To contain the pseudo-element */
  width: 180px;
  border-radius: 20%;
}

.nav ul li a.highlighted {
  color: #ffff; /* Text color for highlighted item */
  border-top: 2px solid #0b2c64;
  border-bottom: 2px solid #0b2c64;
  background-color: (217, 136, 185); /* Neon blue background */
  font-size: 1rem; /* Larger font size for the highlighted item */
  box-shadow: 0 0 15px #0b2c64; /* Glowing effect */
  letter-spacing: 0.1em;
}

.nav ul li a.highlighted::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150%; /* Larger width for the glowing effect */
  height: 150%; /* Larger height for the glowing effect */
  background: #0b2c64; /* Neon glow */
  border-radius: 50%; /* Circular glow */
  transform: translate(-50%, -50%) scale(1.3); /* Center and scale up */
  z-index: -1;
  transition: transform 0.3s ease;
}

.nav ul li a:not(.highlighted) {
  font-size: 1rem; /* Smaller font size for non-highlighted items */
}

.whatsapp-button {
  background-color: #0b2c64;
  color: #fff;
  padding: 8px 15px;
  border-radius: 50%;
  text-decoration: none;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.whatsapp-button:hover {
  background-color: #3561e4;
}

.menu-toggle {
  background: none;
  border: none;
  color: black;
  font-size: 1.5rem;
  cursor: pointer;
}

@media screen and (min-width: 769px) {
  .nav {
    position: static;
    height: auto;
    width: auto;
    padding: 0;
    box-shadow: none;
    background: transparent;
    transform: none;
  }

  .nav ul {
    display: flex;
  }

  .nav ul li {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .nav ul li:last-child {
    margin-right: 0;
  }

  .menu-toggle {
    display: none;
  }
  .search-container {
    flex: 1;
    max-width: 400px;
    margin-right: 20px;
  }
}
