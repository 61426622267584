.gallery-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px;
}
.colorful-bar-gallery {
  background: linear-gradient(90deg, #0b2f9f 0%, #3327e6 100%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ffff;
}
.section_hero {
  margin-bottom: 40px;
  text-align: center;
  padding: 50px;
  background-color: transparent;
  color: cornsilk;
}
.global-reach-gallery {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 5px;
  margin-top: 40px;
}
.global-reach-gallery img {
  width: 100%;
  height: 300px;
  overflow: hidden;
  object-fit: cover;
}
.section_hero img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  overflow: hidden;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin: 20px 0;
}

.gallery-item {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  perspective: 1000px; /* Enables 3D space for the flipping effect */
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease-in-out;
  transform-origin: center;
}

.gallery-item:hover img {
  transform: rotateY(180deg); /* Flip the image on hover */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.6s ease-in-out, transform 0.6s ease-in-out;
}

.gallery-item:hover .overlay {
  opacity: 1;
  transform: rotateY(180deg); /* Rotate the overlay along with the image */
}

.company-overview {
  margin-top: 40px;
  background: #f0f8ff;
  padding: 30px;
  border-radius: 10px;
  text-align: start;
}

.company-overview h2 {
  color: #1a3a6e;
  margin-bottom: 20px;
}

.company-overview p {
  margin: 10px 0;
  line-height: 1.6;
}
@media (max-width: 768px) {
  .global-reach-gallery {
    flex-direction: column;
  }
}
