.product-page {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px;
  text-align: center;
  min-height: 70vh;
}
.colorful-bar-product {
  background: linear-gradient(90deg, rgb(11, 44, 100, 0.8) 0%, #2015c5 100%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #ffff;
}

header h1 {
  margin-top: 20px;
  color: #1a3a6e;
  margin-bottom: 20px;
  font-size: 2.5rem;
}

.category-filter {
  display: flex;
  margin-bottom: 20px;
  justify-content: end;
}

.category-filter button {
  padding: 10px 20px;
  margin: 0 10px;
  background-color: rgba(11, 44, 100, 0.8);
  color: #fff;
  box-shadow: 5px rgba(11, 44, 100, 0.8);
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 25%;
}

.category-filter button:hover {
  background-color: #1f4fe0;
}

.category-details {
  margin-bottom: 20px;
  text-align: left;
}

.category-details h2 {
  font-size: 2rem;
  color: #1a3a6e;
}

.category-details ul {
  list-style-type: none;
  padding: 0;
}

.category-details ul li {
  font-size: 1rem;
  margin-bottom: 10px;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.product-card {
  background-color: #f0f8ff;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.product-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.product-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 15px;
}

.product-card h3 {
  font-size: 1.5rem;
  color: #1a3a6e;
  margin-bottom: 10px;
}

.product-card p {
  font-size: 1rem;
  color: #333;
}
.subcategory-filter {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.subcategory-filter button {
  padding: 8px 16px;
  margin: 0 8px;
  background-color: #0b2f9f;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subcategory-filter button:hover {
  background-color: #0b2f9f;
}

@media (max-width: 768px) {
  .product-page {
    min-height: 0;
  }
  .colorful-bar {
    height: 100px;
  }
  .category-filter {
    flex-direction: column;
  }

  .category-filter button {
    margin-bottom: 10px;
  }
  .product-card img {
    border-radius: 10%;
    height: 250px;
  }
}
