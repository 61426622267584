.search-results {
  padding: 20px;
  background-color: #f9f9f9;
  max-width: 1400px;
  margin: auto;
  margin-top: 70px;
}

.colorful-bar {
  background: linear-gradient(90deg, #00c9ff 0%, #92fe9d 100%);
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.search-results-title {
  font-size: 2rem;
  color: #1a3a6e;
  text-align: center;
  margin-bottom: 20px;
  animation: fadeIn 1s ease-out;
}

.search-results-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.search-result-item {
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.search-result-item:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.search-result-image img {
  width: 100%;
  height: 150px;
  object-fit: cover;
  transition: opacity 0.3s;
}

.search-result-image img:hover {
  opacity: 0.9;
}

.search-result-info {
  padding: 15px;
}

.search-result-info h2 {
  font-size: 1.25rem;
  color: #333;
}

.search-result-info p {
  color: #666;
}

.no-results {
  text-align: center;
  font-size: 1.2rem;
  color: #999;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .colorful-bar {
    height: 100px;
  }
  .search-results-grid {
    grid-template-columns: 1fr;
  }
}
